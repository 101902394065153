h1 {
  color: #012236;
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 0;
}

h2 {
  color: #004062;
  font-size: 20px;
  font-weight: 500;
}

h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
}

h4 {
  color: rgb(0, 70, 100);
  font-size: 13px;
  font-weight: normal;
}

h5 {
  color: #004062;
  font-size: 15px;
  line-height: 140%;
  margin-top: 0;
}

h6 {
  color: #004062;
  font-size: 12px;
  margin-top: 0;
  font-weight: normal;
}

.app,
.welcome {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.app {
  background-color: #f4f4f4;
}

.welcome {
  background: radial-gradient(678.5px at 50% 0%, #00517e 0%, #002236 100%);
}

.main-content {
  max-width: 500px;
  padding: 12px;
  margin: 90px auto 36px;
  text-align: center;
}

.list-unstyled {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.clickable:hover {
  cursor: pointer;
}

.list-unstyled {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
